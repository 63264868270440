<template>
  <div>
    <div class="flex items-center">
      <label class="flex cursor-pointer items-center">
        <input
          ref="checkboxRef"
          :disabled="disabled"
          :checked="display"
          class="peer hidden"
          :name="name"
          type="checkbox"
          @change="onChangeEvent"
          @blur="onBlurEvent"
        />
        <i
          :class="[
            { 'cursor-not-allowed opacity-20': disabled },
            'border-grays-light peer-checked:bg-teal-primary peer-checked:border-teal-primary inline-block h-[24px] w-[24px] rounded border bg-white peer-checked:relative peer-checked:before:absolute peer-checked:before:left-[50%] peer-checked:before:top-[25%] peer-checked:before:h-[12px] peer-checked:before:w-[7px] peer-checked:before:origin-center peer-checked:before:translate-x-[-50%] peer-checked:before:translate-y-[-30%] peer-checked:before:rotate-[40deg] peer-checked:before:border-b-[2px] peer-checked:before:border-r-[2px] peer-checked:before:text-white',
          ]"
        />
        <div class="ml-xs text-n-600 text-[14px]">
          <slot />
        </div>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, ref, watch } from 'vue'

interface ZnCheckboxProps {
  modelValue?: boolean
  checked?: boolean
  disabled?: boolean
  name?: string
}

interface ZnCheckboxEvents {
  (e: 'update:modelValue', value: boolean): void
  (e: 'change', value: boolean): void
  (e: 'blur', value: boolean): void
}

defineComponent({
  name: 'ZnCheckbox',
})

const props = withDefaults(defineProps<ZnCheckboxProps>(), {
  modelValue: false,
  checked: false,
  disabled: false,
  name: '',
})

const emits = defineEmits<ZnCheckboxEvents>()

const display = ref<boolean>(props.modelValue || props.checked)

const onChangeEvent = ($event: Event) => {
  const el = $event.target as HTMLInputElement
  emits('update:modelValue', el.checked)
  emits('change', el.checked)
}

const onBlurEvent = ($event: Event) => {
  const el = $event.target as HTMLInputElement
  emits('blur', el.checked)
}

watch(
  () => props.modelValue,
  (newValue) => {
    display.value = newValue
  },
)
</script>
